/* This is the Golbal Parameter */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
/* font-family: 'Fredoka One', cursive; */

@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&display=swap');
/* font-family: 'Dongle', sans-serif; */
/* color : code for body #11181b, #1d2331 */


/* Some Color Pallets  */
/* Color Pallet One  */
/* - 001d3d
- 003566
- ffc300
- ffd60a */


/* These are the Color which is being currently used by the crio.do website  */
/* Fro nav - #2df8c5
for text - black + green 
fro buttons - black + #ffd500
for background - white 
for soft high-light - light green + #f2fbf7
for dark highligth - dark green #07634c
for more dark highlight - dark dark green - #004246 #004246 */


body {
  min-height: 100vh;
  background: linear-gradient(to bottom, #f2fbf7, white);
  /* background-color: red; */
  font-family: 'Fredoka One', cursive;
}

/* This is the Navbar */
.navbar {
  background: #2df8c5;
  color: white;
  font-weight: 700;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}

.custom-brand {
  background: linear-gradient(to right, red, yellow, green, blue);
  opacity: 100%;
  font-size: 3rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-brand img {
  width: 150px;
}

.btn-nav {
  color: black;
  font-weight: 600;
  color: orange;
  border: 2px solid orange;
  background: transparent;
  border-radius: 10px;
  font-size: 1.6rem;
  padding: 5px 20px 5px 20px;
}

.btn-nav:hover {
  background: #ffc300;
  color: black;
  border: 2px solid #ffc300;
  transition: 0.3s;
}

.nav-item {
  display: flex;
  align-items: baseline;
}

.nav-links {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  padding: 10px;
}

.nav-links:hover {
  color: orange;
  transition: 0.3s;
}


/* This is the Register Page */
.main-register {
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
}

.register-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 40vw;
  background-color: #004246;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 10px;
  transition: 0.3s;
}

/* .register-menu:hover {
  max-width: 42vw;
  background-color: #2a2e35;
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.2);
} */

.register-menu input {
  background-color: gainsboro;
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;

}

.register-menu textarea {
  background-color: gainsboro;
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}

.register-menu select {
  border: 1px solid gray;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}


.register-menu label {
  border: 1px solid gray;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}

.register-menu button {
  background-color: #ffd500;
  border: 2px solid #ffd500;
  color: white;
  font-weight: 700;
  margin-top: 10px;
  font-size: 1.2rem;
  padding: 5px 50px 5px 50px;
  border-radius: 10px;
  font-family: 'Dongle', sans-serif;
  font-size: 1.8rem;
}

.register-menu button:hover {
  background-color: #ffef9f;
  border: 2px solid #ffef9f;
  transition: 0.3s;
}

.text-input {
  display: flex;
  width: 100%;
  max-width: 50vw;
  flex-direction: column;
  align-self: flex-start;
  margin: 5px;
  font-family: 'Dongle', sans-serif;
  font-size: 2rem;
}

.text-input h3 {
  font-family: 'Dongle', sans-serif;
  color: white;
  font-size: 3.5rem;
  line-height: 0.5;
}

.text-input p {
  font-family: 'Dongle', sans-serif;
  font-size: 2rem;
  color: white;
  line-height: 0.7;
}

.privacy-text {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 5px;
  line-height: 0.5;
  color: white;
  margin-top: 25px;
  align-items: flex-start;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}

.privacy-text span {
  color: blue;
  text-decoration: none;
}


.name-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.name-input input {
  width: 100%;
  max-width: 50vw;
}

.email-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.birth-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.birth-input input {
  width: 100%;
  max-width: 50vw;
}


.branch-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}


.input-group {
  width: 100%;
  max-width: 50vw;
  margin: 5px;
  font-size: 1rem;
}

.input-group .form-select {
  background-color: gainsboro;
}

.social-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hobby-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.password-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* This is Login Page  */
.login-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 25vw;
  background-color: #004246;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  margin-bottom: 50px;
  transition: 0.3s;
  padding: 20px;
  border-radius: 10px;
}


.login-menu input {
  background-color: gainsboro;
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 1rem;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}

.login-menu button {
  background-color: #ffd500;
  padding: 2px;
  margin: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  border: 1px solid #ffd500;
  font-family: 'Dongle', sans-serif;
  transition: 0.3s;
  font-size: 2rem;
}

.login-menu button:hover {
  background-color: #ffef9f;
  border: 1px solid #ffef9f;
  transition: 0.5s;
  color: ffc300;
}

.login-menu h5 {
  color: white;
  font-family: 'Dongle', sans-serif;
  font-size: 2rem;
}

.login-menu .register-button {
  padding: 5px 25px 5px 25px;
}



/* This is the Error Page  */
.error-page {
  display: flex;
  margin-top: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-page h1 {
  font-size: 10rem;
  opacity: 20%;
  font-weight: 700;
  line-height: 0.9;
  color: white;
}

.error-page h3 {
  color: blue;
  font-family: 'Dongle', sans-serif;
  font-size: 4rem;
}

.error-page button {
  background-color: green;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px 20px 5px 20px;
  border: 2px solid green;
}

.error-page button:hover {
  background-color: lightgreen;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px 20px 5px 20px;
  border: 2px solid lightgreen;
  transition: 0.3s;
  color: green;
}

.error-page .nav-text {
  color: white;
  text-decoration: none;
  font-weight: 600;

}

/* This is for the contact Page  */
.main-contact {
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.contact-email {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 30vw;
  background-color: #004246;
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  transition: 0.5s;
}

.contact-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 40vw;
  flex-direction: row;
  padding: 20px;
  border-radius: 10px;
  transition: 0.5s;
}

.contact-email h1 {
  color: white;
  font-family: 'Dongle', sans-serif;
  font-size: 4rem;
  line-height: 0.5;
}

.contact-email p {
  color: white;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
  line-height: 0.5;
}


.contact-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 40vw;
  background-color: #004246;
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 10px;
  transition: 0.5s;
}

/* .contact-menu:hover {
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.2);
  max-width: 42vw;
} */


.contact-menu input {
  background-color: gainsboro;
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}

.contact-menu textarea {
  background-color: gainsboro;
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 1rem;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}

.contact-button {
  background-color: #ffd500;
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  color: white;
  font-weight: 700;
  border: 1px solid #ffd500;
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
}

.contact-button:hover {
  background-color: #ffef9f;
  border: 1px solid #ffef9f;
  transition: 0.3s;
}


/* This is Footer  */
footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #001d3d;
  background: ghostwhite;
  font-family: 'Dongle', sans-serif;
}

.line-div {
  width: 10%;
  height: 5px;
  background: linear-gradient(to right, #001d3d, #6b9080);
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
}

.nav-linkf {
  text-decoration: none;
  color: #001d3d;
  font-size: 1.7rem;
  padding: 10px;
}

.nav-linkf:hover {
  color: orange;
  transition: 0.3s;
}

.nav-linki {
  text-decoration: none;
  color: #001d3d;
  font-size: 1.5rem;
  margin: 5px;
  margin-bottom: 10px;
}

.nav-linki:hover {
  color: orange;
  transition: 0.3s;
}

.nav-linkt {
  text-decoration: none;
  color: #001d3d;
  font-size: 1.6rem;
  font-weight: 800;
  margin: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}



/* This is the profile page  */
.profile-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 50vw;
  background-color: #f2fbf7;
  /* background-color: lightblue; */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  transition: 0.5s;
  color: black;
}

.search-profile {
  border-radius: 10px;
  background-color: #f2fbf7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}


.main-search-input {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #f2fbf7;
}



.img-about {
  text-align: center;
  font-family: 'Dongle', sans-serif;
  font-size: 2rem;
  line-height: 0.8;
  width: 100%;
  /* background-color: yellow; */
}

.dad-img {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
}

.background-profile {
  width: 100%;
  /* position: absolute; */
  border-radius: 10px 10px 0px 0px;
  height: 220px;
}

.forground-profile {
  position: absolute;
  margin-top: 100px;
  width: 200px;
  border-radius: 200px;
  border: 10px solid white;
  align-self: flex-start;
  margin-left: 50px;
}


.img-about h3 {
  font-family: 'Dongle', sans-serif;
  font-size: 3rem;
  line-height: 1;
}

.text-about {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  text-align: center;
  font-family: 'Dongle', sans-serif;
  font-size: 2rem;
  line-height: 0.5;
  width: 90%;
  background-color: pink;
  margin-bottom: 30px;
  border-radius: 15px;
}

.text-about table {
  width: 100%;
  align-items: center;
}

.text-about td {
  border: 1px solid #f2fbf7;
  text-align: start;
  padding: 8px;
  line-height: 0.9;
}

.email-text-profile {
  width: 100%;
  max-width: 80vw;
  text-align: center;
}

.about-final-text {
  text-align: start;
  font-family: 'Dongle', sans-serif;
  font-size: 2rem;
  margin-bottom: 20px;
  line-height: 0.9;
  background-color: lightskyblue;
  border-radius: 10px;
  padding: 15px;
  width: 90%;
}

.about-final-text h3 {
  font-family: 'Dongle', sans-serif;
  font-size: 3rem;
  line-height: 0.6;
}

.social-about {
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-family: 'Dongle', sans-serif;
  font-size: 2rem;
  line-height: 0.5;
  width: 90%;
  background-color: lightgreen;
  margin-bottom: 30px;
  border-radius: 15px;
}

.social-about table {
  width: 100%;
  align-items: center;
}

.social-about td {
  border: 1px solid #f2fbf7;
  text-align: start;
  padding: 8px;
  line-height: 0.9;
}


/* This is about page  */
.about-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
  max-width: 60vw;
  text-align: center;
}

.h1-text {
  background: linear-gradient(to right, red, yellow, green, blue);
  opacity: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5rem;
  line-height: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Dongle', sans-serif;
}

.about-page p {
  color: #001d3d;
  font-size: 2rem;
  line-height: 1;
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: 'Dongle', sans-serif;
}

.about-page img {
  width: 200px;
  margin-bottom: 50px;
}

.div-img {
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}


.div-img img {
  margin: 20px;
  width: 200px;
  height: 200px;
  border-radius: 200px;
  position: relative;
}

.div-img-div h3 {
  color: black;
  font-family: 'Dongle', sans-serif;
  font-size: 3rem;
  line-height: 0.5;
  margin-top: 10px;
}

.div-img-div p {
  color: black;
  font-family: 'Dongle', sans-serif;
  line-height: 0.2;
  /* background-color: yellow; */
  margin-top: 10px;
}

.about-social-link {
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
}

.nav-linkk {
  font-size: 1.5rem;
  color: black;
}

.nav-linkk:hover {
  font-size: 1.5rem;
  color: #ffd500;
}

.div-h1 h1 {
  margin-top: 50px;
  font-size: 4rem;
  line-height: 1;
  font-family: 'Dongle', sans-serif;
}

.h1-color {
  font-size: 4rem;
  line-height: 1;
  color: #001d3d;
  font-family: 'Dongle', sans-serif;
  background: linear-gradient(to right, red, yellow, green, blue);
  opacity: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.div-h2 {
  margin-top: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.div-h2 h1 {
  color: white;
  font-size: 4rem;
  line-height: 1;
  color: #001d3d;
  font-family: 'Dongle', sans-serif;
  background: linear-gradient(to right, red, yellow, green, blue);
  opacity: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hr-text {
  width: 30%;
  height: 5px;
  border-radius: 20px;
  opacity: 100%;
  background: linear-gradient(to right, red, yellow, green, blue, purple);
  margin-bottom: 20px;
}

.div-h2 p {
  font-size: 2rem;
  line-height: 1;
  color: #001d3d;
  font-family: 'Dongle', sans-serif;
}


.div-h3 {
  margin-top: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #2df8c5;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 50px;
}

.div-h3 h1 {
  margin-top: 10px;
  color: white;
  font-size: 4rem;
  line-height: 1;
  color: #b2becd;
  font-family: 'Dongle', sans-serif;
  background: linear-gradient(to right, red, yellow, green, blue);
  opacity: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.div-h3 p {
  font-size: 2rem;
  line-height: 1;
  color: #001d3d;
  font-family: 'Dongle', sans-serif;
}


/* This is the News Page  */
.news-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 70vw;
  background-color: #004246;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 15px;
  border-radius: 10px;
  transition: 0.3s;
  color: white;
}

.img-news-div {
  max-width: 20vw;
  width: 100%;
  text-align: center;
  /* background-color: yellow; */
}

.img-news-div img {
  width: 350px;
  height: 180px;
}

.text-news-div {
  max-width: 50vw;
  width: 100%;
  /* background-color: pink; */

}

.news-text-main {
  width: 100%;
  max-width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
}

.news-text-main h1 {
  font-size: 4rem;
  margin-top: 50px;
  font-family: 'Dongle', sans-serif;
  margin-bottom: 0px;
  line-height: 0.5;
}

.news-text-main p {
  color: black;
  text-align: center;
  font-size: 2rem;
  font-family: 'Dongle', sans-serif;
  margin-top: 20px;
  line-height: 0.9;
}

.news-menu img {
  width: 250px;
  border-radius: 20px;
  margin: 40px;
}

.news-menu h1 {
  font-size: 3rem;
  line-height: 1;
  color: white;
  font-family: 'Dongle', sans-serif;
}

.news-menu h4 {
  font-size: 2rem;
  line-height: 1;
  color: white;
  font-family: 'Dongle', sans-serif;
}

.news-menu p {
  font-size: 2rem;
  line-height: 1;
  color: #b2becd;
  font-family: 'Dongle', sans-serif;
}


.news-menu button {
  padding: 10px;
  background-color: #ffd500;
  color: white;
  font-weight: 700;
  border: 2px solid #ffd500;
  border-radius: 10px;
}

.news-menu a {
  text-decoration: none;
  color: white;
}

/* This is for the Thanks Page  */
.thanks-page {
  color: #b2becd;
  font-family: 'Dongle', sans-serif;
  text-align: center;
  width: 100%;
  max-width: 60vw;
  background-color: #001d3d;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  transition: 0.3s;
  color: white;
  margin-top: 50px;
}

.thanks-page h2 {
  color: red;
  font-size: 5rem;
}

.thanks-page h4 {
  font-size: 2rem;
}

.thanks-page button {
  padding: 5px 20px 5px 20px;
  font-size: 1.5rem;
  border-radius: 20px;
  background-color: #ffc300;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  color: #001d3d;
}

.thanks-page button:hover {
  padding: 5px 20px 5px 20px;
  font-size: 1.5rem;
  border-radius: 20px;
  color: #001d3d;
  background-color: #ffd60a;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
}


/* This is for the Home Page  */
.main-home {
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.home-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
  transition: 0.3s;
}

.section-one-home {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 60vw;
  color: black;
}

.home-one-right {
  max-width: 30vw;
  width: 100%;
  /* background-color: lightblue; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Dongle', sans-serif;
}

.home-one-right h1 {
  font-size: 4rem;
  line-height: 0.7;
  max-width: 20vw;
}

.home-one-right h4 {
  font-size: 2rem;
  line-height: 0.7;
}

.home-one-right p {
  font-size: 1.5rem;
  line-height: 0.9;
  max-width: 25vw;
}

.home-one-right button {
  padding: 5px 20px 5px 20px;
  border-radius: 10px;
  border: 2px solid #ffd500;
  background-color: #ffd500;
  font-size: 1.5rem;
  color: white;
  /* box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2); */
}

.home-one-right button:hover {
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
  background-color: #ff4d6d;
  border: 2px solid #ff4d6d;
}

.home-one-left {
  width: 100%;
  max-width: 30vw;
  /* background-color: lightcoral; */
  display: flex;
  justify-content: flex-end;
}

.home-one-left img {
  width: 300px;
  /* box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2); */
}


.social-link {
  margin-top: 100px;
  max-width: 60vw;
  width: 100%;
}

.social-link h1 {
  text-align: center;
  color: black;
  font-family: 'Dongle', sans-serif;
  font-size: 3rem;
  line-height: 1;
}

.social-link-div {
  width: 100%;
  background-color: #2df8c5;
  height: 120px;
  border-radius: 20px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.fab-icon {
  margin-left: 50px;
  margin-right: 50px;
  font-size: 3rem;
  color: #004246;
}

.fab-icon:hover {
  /* box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2); */
  text-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  color: #ffd500;
}

.section-two-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 70vw;
  color: black;
  text-align: center;
  margin-top: 200px;
  /* background-color: yellow; */
  font-family: 'Dongle', sans-serif;
}

.section-two-home h1 {
  max-width: 30vw;
  margin-bottom: 20px;
  font-size: 4rem;
  line-height: 0.7;
  margin-bottom: 30px;
}

.section-two-home p {
  max-width: 40vw;
  font-size: 2rem;
  line-height: 0.8;
}

.card-div-home {
  margin-top: 20px;
  width: 100%;
  max-width: 90vw;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  /* background-color: lightpink; */
}

.card-div-home img {
  width: 100px;
  margin-bottom: 20px;
}

.card-div-center {
  max-width: 20vw;
  width: 100%;
  padding: 40px;
  margin: 10px;
  background-color: #2df8c5;
  border-radius: 20px;
}

.card-div-center:hover {
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-div-center h1 {
  font-family: 'Dongle', sans-serif;
  line-height: 0.8;
  font-size: 2.5rem;
}

.card-div-center p {
  font-family: 'Dongle', sans-serif;
  line-height: 0.8;
  font-size: 1.5rem;
}

.line-hr {
  width: 200px;
  background-color: black;
  height: 4px;
  border-radius: 20px;
  margin-top: 20px;
}

.section-four-home {
  text-align: center;
  color: black;
  margin-top: 50px;
  margin-bottom: 50px;
}

.section-four-home h2 {
  font-family: 'Dongle', sans-serif;
  font-size: 3rem;
  line-height: 0.6;
}

.section-four-home h1 {
  font-family: 'Dongle', sans-serif;
  font-size: 5rem;
  line-height: 0.6;
}

.section-four-home p {
  font-family: 'Dongle', sans-serif;
  font-size: 2rem;
  line-height: 0.9;
}

.section-four-home button {
  font-family: 'Dongle', sans-serif;
  font-size: 1.5rem;
  line-height: 0.9;
  border-radius: 10px;
  border: 2px solid #ffd500;
  background-color: #ffd500;
  padding: 10px;
  color: white;
}

.section-four-home button:hover {
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
  background-color: #ff4d6d;
  border: 2px solid #ff4d6d;
}


@media (max-width: 1000px) {
  .register-menu {
    max-width: 90vw;
    width: 100%;
  }

  .register-menu:hover {
    max-width: 90vw;
    width: 100%;
  }

  .privacy-text {
    line-height: 1;
  }

  .login-menu {
    max-width: 80vw;
    width: 100%;
  }

  /* .login-menu:hover {
    max-width: 90vw;
    width: 100%;
  } */

  .contact-menu {
    max-width: 90vw;
    width: 100%;
  }

  .contact-menu:hover {
    max-width: 90vw;
    width: 100%;
  }

  .profile-menu {
    width: 100%;
    max-width: 90vw;
  }


  .contact-data {
    width: 100%;
    max-width: 90vw;
    flex-direction: column;
  }

  .contact-email {
    width: 100%;
    max-width: 90vw;
  }


  /* This is for the Profile  */
  .img-about {
    text-align: center;
    font-family: 'Dongle', sans-serif;
    font-size: 2rem;
    line-height: 0.8;
    width: 100%;
    /* background-color: yellow; */
  }

  .dad-img {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    justify-content: center;
    align-items: center;
  }

  .background-profile {
    width: 100%;
    /* position: absolute; */
    border-radius: 10px 10px 0px 0px;
    height: 220px;
  }

  .forground-profile {
    align-self: center;
    margin-left: 0px;
    position: absolute;
    margin-top: 80px;
    width: 200px;
    border-radius: 200px;
    border: 10px solid white;
  }

  .search-profile {
    max-width: 90vw;
    width: 100%;
  }

  .search-profile h4 {
    font-size: 1rem;
  }


  .main-search-input {
    width: 60%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #f2fbf7;
  }

  /* This is the news Section  */
  .news-text-main {
    width: 100%;
    max-width: 90vw;
  }

  .news-menu img {
    width: 300px;
  }

  .news-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    transition: 0.3s;
    color: white;
  }

  .img-news-div {
    max-width: 90vw;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background-color: yellow; */
  }

  .text-news-div {
    max-width: 90vw;
    width: 100%;
    /* background-color: pink; */
    text-align: center;
  }

  /* This is the home page  */
  .home-menu {
    width: 100%;
    max-width: 90vw;
    /* background-color: yellow; */
  }

  .section-one-home {
    width: 100%;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: yellow; */
  }

  .home-one-right {
    text-align: center;
    max-width: 90vw;
    width: 100%;
  }

  .home-one-right h1 {
    text-align: center;
    max-width: 90vw;
    width: 100%;
  }

  .home-one-right p {
    text-align: center;
    max-width: 90vw;
    width: 100%;
  }

  .home-one-left {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  .home-one-left img {
    width: 400px;
  }

  .social-link {
    display: none;
    max-width: 90vw;
  }

  .social-link-div {
    display: none;
    width: 100%;
    max-width: 90vw;
    border-radius: 20px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }

  .fab-icon {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 2rem;
    color: red;
  }

  .section-two-home {
    width: 100%;
    max-width: 90vw;
  }

  .section-two-home h1 {
    width: 100%;
    max-width: 90vw;
  }

  .section-two-home p {
    width: 100%;
    max-width: 90vw;
  }

  .card-div-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90vw;
  }

  .card-div-center {
    width: 100%;
    max-width: 90vw;
  }


  .section-five-home {
    width: 100%;
    max-width: 90vw;
    text-align: center;
  }

  .card-section-five {
    max-width: 90vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
}